html,
body,
div,
span {
  height: 100%;
  width: 100%;
  /*   overflow: hidden; */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-size: cover;
}

.fa-2x {
  font-size: 1.5em;
}

.app {
  position: relative;
  overflow: hidden;
  /*  top: 19px;*/
  /*height: calc(100% - 38px);*/
  margin: auto;
  padding: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.app-one {
  background-color: #f7f7f7;
  /*height: 100%;*/
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.container {
  width: 99% !important;
  height: 95% !important;
}

.side {
  padding: 0;
  margin: 0;
  height: 100%;
}
.side-one {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  display: block;
  top: 0;
}

.side-two {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
  top: -100%;
  left: -100%;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
}

.heading {
  padding: 10px 16px 10px 15px;
  margin: 0;
  /*   height: 60px; */
  width: 100%;
  background-color: #eee;
  z-index: 1000;

  height: 10vh;
  top: 0;
  left: 0;
  right: 0;
}

.heading-avatar {
  padding: 0;
  cursor: pointer;
}

.heading-avatar-icon img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.heading-name {
  padding: 0 !important;
  cursor: pointer;
  float: left;
}

.heading-name-meta {
  font-weight: 700;
  font-size: 100%;
  padding: 5px;
  padding-bottom: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  display: block;
  display: inline;
  width: 40%;
}
.heading-online {
  display: inline;
  padding: 0 5px;
  font-size: 12px;
  color: #93918f;
}
.heading-compose {
  padding: 0;
}

.heading-compose i {
  text-align: center;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.heading-dot {
  padding: 0;
  margin-left: 10px;
}

.heading-dot i {
  text-align: right;
  padding: 5px;
  color: #93918f;
  cursor: pointer;
}

.searchBox {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.searchBox-inner {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}

/*#searchBox-inner input {
  box-shadow: none;
}*/

.searchBox-inner input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  /*height: calc(100% - 20vh); */
  height: 75vh;
  left: 0;
  right: 0;
  bottom: 0;
}

.sideBarDown {
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #f7f7f7;
  height: calc(45% - 20vh);
}

.sideBar-body {
  position: relative;
  padding: 10px !important;
  border-bottom: 1px solid #f7f7f7;
  height: 72px;
  margin: 0 !important;
  cursor: pointer;
}

.sideBar-body:hover {
  background-color: #f2f2f2;
}

.sideBar-bodyInfo {
  position: relative;
  padding: 10px !important;
  border-bottom: 1px solid #f7f7f7;
  height: 110px;
  margin: 0 !important;
  cursor: pointer;
}

.sideBar-bodyInfo:hover {
  background-color: #f2f2f2;
}

.sideBar-avatar {
  text-align: center;
  padding: 0 !important;
}

.avatar-icon img {
  border-radius: 50%;
  height: 49px;
  width: 49px;
}

.sideBar-main {
  padding: 0 !important;
}

.sideBar-main .row {
  padding: 0 !important;
  margin: 0 !important;
}

.sideBar-name {
  padding: 10px !important;
}

.name-meta {
  font-size: 100%;
  padding: 1% !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.sideBar-time {
  padding: 10px !important;
}

.time-meta {
  text-align: right;
  font-size: 12px;
  padding: 1% !important;
  color: rgba(0, 0, 0, 0.4);
  vertical-align: baseline;
}

/*New Message*/

.newMessage {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  position: relative;
  left: -100%;
}
.newMessage-heading {
  padding: 10px 16px 10px 15px !important;
  margin: 0 !important;
  height: 100px;
  width: 100%;
  background-color: #00bfa5;
  z-index: 1001;
}

.newMessage-main {
  padding: 10px 16px 0 15px !important;
  margin: 0 !important;
  height: 60px;
  margin-top: 30px !important;
  width: 100%;
  z-index: 1001;
  color: #fff;
}

.newMessage-title {
  font-size: 18px;
  font-weight: 700;
  padding: 10px 5px !important;
}
.newMessage-back {
  text-align: center;
  vertical-align: baseline;
  padding: 12px 5px !important;
  display: block;
  cursor: pointer;
}
.newMessage-back i {
  margin: auto !important;
}

.composeBox {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.composeBox-inner {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}

.composeBox-inner input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.compose-sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 160px);
}

/*Conversation*/

.conversation {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  /* width: 100%; */
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  overflow-y: auto;
}

.message {
  padding: 0 !important;
  margin: 0 !important;
  background-size: cover;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 120px);

  top: 10vh;
  left: 0;
  right: 0;
  bottom: 10px;
}
.message-previous {
  margin: 0 !important;
  padding: 0 !important;
  height: auto;
  width: 100%;
}
.previous {
  font-size: 15px;
  text-align: center;
  padding: 10px !important;
  cursor: pointer;
}

.previous a {
  text-decoration: none;
  font-weight: 700;
}

.message-body {
  margin: 0 !important;
  padding: 0 !important;
  width: auto;
  height: auto;
}

.message-main-receiver {
  padding: 3px 20px !important; /*  20px; */
  max-width: 60%;
}

.message-main-sender {
  padding: 3px 20px !important;
  margin-left: 40% !important;
  max-width: 60%;
}

.message-main-nota {
  text-align: center;
  padding: 3px 20px !important;
}

.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
}

.message-time {
  margin: 0 !important;
  margin-left: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;
}

.receiver {
  width: auto !important;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  background: #ffffff;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  display: inline-block;
}

.sender {
  float: right;
  width: auto !important;
  background: #dcf8c6;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  word-wrap: break-word;
}

.senderLocal {
  float: right;
  width: auto !important;
  background: #c6eff8;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  word-wrap: break-word;
}

.notaPrivada {
  width: auto !important;
  border-color: #feedaf;
  background-color: #feedaf;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  word-wrap: break-word;
  text-align: justify;
}

.notaPrivada > .message-text{
  width: 45em;
}

/*Reply*/

.reply {
  height: 40px;
  width: 100%;
  background-color: #f5f1ee;
  padding: 10px 5px 10px 5px !important;
  margin: 0 !important;
  z-index: 1000;
}

.reply-emojis {
  padding: 5px !important;
}

.reply-emojis i {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-recording {
  padding: 5px !important;
}

.reply-recording i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-send {
  padding: 5px !important;
  height: auto;
}

.reply-send i {
  text-align: center;
  /* padding: 5px !important; */
  color: #93918f;
  cursor: pointer;
}

.reply-main {
  /* width: 78% !important; */
  padding: 2px 5px !important;
}

.reply-main textarea {
  width: 100%;
  /*   resize: vertical; */
  overflow: hidden;
  padding: 5px !important;
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  height: 100%;
  font-size: 16px;
}

.reply-main textarea:focus {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  resize: none;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .app {
    top: 0;
    height: 100%;
  }
  .heading {
    height: 70px;
    background-color: #009688;
  }
  .fa-2x {
    font-size: 2.3em !important;
  }
  .heading-avatar {
    padding: 0 !important;
  }
  .heading-avatar-icon img {
    height: 50px;
    width: 50px;
  }
  .heading-compose {
    padding: 5px !important;
  }
  .heading-compose i {
    color: #fff;
    cursor: pointer;
  }
  .heading-dot {
    padding: 5px !important;
    margin-left: 10px !important;
  }
  .heading-dot i {
    color: #fff;
    cursor: pointer;
  }
  .sideBar {
    height: calc(100% - 130px);
  }
  .sideBar-body {
    height: 80px;
  }
  .sideBar-bodyInfo {
    height: 80px;
  }
  .sideBar-avatar {
    text-align: left;
    padding: 0 8px !important;
  }
  .avatar-icon img {
    height: 55px;
    width: 55px;
  }
  .sideBar-main {
    padding: 0 !important;
  }
  .sideBar-main .row {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sideBar-name {
    padding: 10px 5px !important;
  }
  .name-meta {
    font-size: 16px;
    padding: 5% !important;
  }
  .sideBar-time {
    padding: 10px !important;
  }
  .time-meta {
    text-align: right;
    font-size: 14px;
    padding: 4% !important;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: baseline;
  }
  /*Conversation*/
  .conversation {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    width: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    overflow-y: auto;
  }
  .message {
    height: calc(100% - 140px);
  }
  .reply {
    height: 70px;
  }
  .reply-emojis {
    padding: 5px 0 !important;
  }
  .reply-emojis i {
    padding: 5px 2px !important;
    font-size: 1.8em !important;
  }
  .reply-main {
    padding: 2px 8px !important;
  }
  .reply-main textarea {
    padding: 8px !important;
    font-size: 18px;
  }
  .reply-recording {
    padding: 5px 0 !important;
  }
  .reply-recording i {
    padding: 5px 0 !important;
    font-size: 1.8em !important;
  }
  .reply-send {
    padding: 5px 0 !important;
  }
  .reply-send i {
    padding: 5px 2px 5px 0 !important;
    font-size: 1.8em !important;
  }
}

.bandera {
  width: 40px;
}

.radio {
  margin-left: 3em;
}

#conversation {
  /*   resize: vertical; */
  height: 75vh;
  top: 0;
  position: absolute;
  overflow: auto;
}

#responder {
  /*height: 100%;*/
  height: 10vh;
  bottom: 0;
  position: absolute;
  overflow: auto;
}

textarea.form-control {
  height: auto;
}

.superior {
  height: 3vh;
  width: 100% !important;
}

.inferior {
  height: 3vh;
  width: 100% !important;
}

.conversacion-activa {
  background-color: lightgray;
}

.heading,
.message,
.contenedor,
.sideBar {
  position: absolute;
  overflow: auto;
}

/* .heading {
  height: 10vh;
  top: 0;
  left: 0;
  right: 0;
} */

/* .message {
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 10px;
} */

/* #responder {
  height: 10vh;
  left: 0;
  right: 0;
  bottom: 0;
} */

/* .sideBar {
  height: 75vh;
  left: 0;
  right: 0;
  bottom: 0;
} */

.contenedor {
  height: 85vh;
  left: 0;
  right: 0;
  bottom: 0;
}

/* #conversation {
  height: 75vh;
  top: 0;
  position: absolute;
  overflow: auto;
} */

/* #responder {
  height: 10vh;
  bottom: 0;
  position: absolute;
  overflow: auto;
} */

/* #responder,
#conversation {
  position: absolute;
  overflow: auto;
} */

.leidos {
  color: black;
}

.traducir {
  float: right;
}

.modoNotaPrivada-hidden {
  display: none;
}

.numero-sin-leer {
  background: #01e676;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
  height: auto;
}

.reply-modo {
  width: 100%;
  padding: 0;
  height: 25%;
  margin-bottom: 1vh;
}

.reply-modo > input {
  margin-left: 2vh;
  color: #737376;
  opacity: 0.7;
  border-bottom: 2px solid transparent;
}

.reply-modo > input.selected {
  color: #334bfa;
  font-weight: bold;
  opacity: 1;
  border-bottom-color: #334bfa;
}

.reply-modo > input:hover {
  color: #334bfa;
  font-weight: bold;
}

.notaPrivada-mensaje {
  border-color: #feedaf !important;
  background-color: #feedaf !important;
  width: 100% !important;
}

.rhap_main {
  width: 50% !important;
}

.rhap_container {
  box-shadow: none !important;
}

.message-file {
  background-color: #f2f2f2;
  padding: 3px;
  border-radius: 6px 6px 6px 6px;
  /* box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%) !important; */
}

.link-file:hover {
  text-decoration: none;
}

.input-oculto {
  display: none !important;
}

.boton-traducir {
  padding-left: 30px !important;
}

.select-canal {
  margin-left: 25px;
}