.login-form {
  width: 100%;
}

.mensaje {
  display: block;
  font-family: Poppins-Bold, sans-serif;
  font-size: 45px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  height: 20vh;
  margin-top: 5%;
}

.logo {
  display: block;
  width: 50vh;
  overflow: hidden;
  margin: 0 auto;
}

.logo img {
  width: 100%;
}
