.search {
  position: relative;
  width: 30%;
  height: 35px;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 700px) {
  .search {
    width: 100%;
  }
}

.search-icon {
  z-index: 1;
  position: absolute;
  top: 9px;
  left: 28px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 18px;
  height: 18px;
}

.search-input {
  box-sizing: border-box;
  background-color: #1f364d;
  border-radius: 4px;
  border: 0;
  padding-left: 35px;
  color: white;
  opacity: 0.8;
  transition: opacity 0.2s;
  width: 100%;
  height: 35px;
}

.search-input:focus {
  outline: none;
  opacity: 1;
}

.search ::placeholder {
  color: #9cb3c9;
  opacity: 1;
}

.search-loading {
  position: absolute;
  top: 9px;
  right: 28px;
}

.search-result-container {
  /*   margin-top: 60px !important;
 */
  z-index: 1;
  position: absolute;
  height: 240px;
  width: 100%;
  max-height: 299px;
  overflow-y: auto;
  background-color: #0f273d;
  border: 1px solid #0c2033;
  border-radius: 4px;
  box-shadow: 0px 0px 40px 0px #1f364d;
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 20px;
  overflow: auto;
}

.search-result {
  color: #9cb3c9;
  padding: 15px 0 15px 35px;
  height: 60px;
  border-bottom: 2px solid #0c2033;
  cursor: pointer;
}

.search-result:hover {
  color: #fff;
}

.search-no-result {
  color: #9cb3c9;
  padding: 15px 0 15px 35px;
  border-bottom: 1px solid #0f273d;
}

/* .search-result-container {
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 20px;
  position: absolute;
  overflow: auto;
} */

.searchBox-inner {
  height: 10vh;
  top: 0;
  left: 0;
  right: 0;
}

.searchBox {
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 10px;
  position: absolute;
}
/* .searchBox {
  position: absolute;
} */
